<template>
  <div>
    <page-title :heading="$t('tablebee.lang_panic_table') "
                :subheading="$t('tablebee.lang_panic_table')" :icon=icon></page-title>
    <div class="app-main__inner">
      <panic-table-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import PanicTableComponent from "@/components/tableBee/panicTableComponent";


export default {
  name: "TableManagerView",
  components: {
    PanicTableComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-box1 icon-gradient bg-tempting-azure',
  })
}
</script>

